.c-counter {
  margin-top: 1em;
  color: $gray;
  font-size: 22px;
  p {
    font-size: 14px;
    display: block;
    span {
      font-size: 10px;
      display: block;
    }
  }
  .c-counter__num {
    width: 0.75em;
    font-size: 100px;
    color: $white;
    background: $orange;
    border-radius: 4px;
    display: inline-block;
    margin: 0 2px;
    font-weight: 100;
    padding: 5px 10px 3px 10px;
    &:first-child {
      margin-left: 10px;
    }
    &:last-child {
      margin-right: 10px;
    }
  }
}
