.u-center {
  text-align: center;
  p + ul {
    margin-top: 0;
  }
  ul {
    display: inline-block;
  }
}
.u-right {
  text-align: right;
}
.u-left {
  text-align: left;
}
