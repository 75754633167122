.c-power-score {
  width: 100%;
  padding: 10px 0 9px 0;
  font-weight: 100;
}
.c-power-score__inner {
  @include clf;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
%c-power-score__detail {
  @include clf;
  display: inline-block;
  height: 70px;
  border-radius: 4px;
  padding: 7px 10px;
  @media screen and (max-width: $bp-lg) {
    padding: 12px 10px;
  }
  font-weight: 100;
}
.c-power-score__detail--left {
  @extend %c-power-score__detail;
  color: $green;
}
.c-power-score__detail--right {
  @extend %c-power-score__detail;
  color: $orange;
}
.c-power-score__detail__data {
  @extend .u-fleft;
  div {
    &:first-child {
      margin: 0.6em 15px 0.3em 0;
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      a {
        display: inline-block;
        margin: -0.4em 0 -0.4em 0;
        vertical-align: bottom;
        cursor: pointer;
        img {
          width: 1.8em;
        }
      }
    }
    &:last-child {
      margin-right: 15px;
      text-align: left;
      font-size: 26px;
      @media screen and (max-width: $bp-lg) {
        font-size: 20px;
      }
      span {
        font-size: 13px;
        font-weight: 200;
      }
    }
  }
}
.c-power-score__detail__cost {
  @extend .u-fright;
  padding-top: 2px;
  font-size: 53px;
  @media screen and (max-width: $bp-lg) {
    font-size: 44px;
  }
  span {
    font-size: 16px;
  }
}
