%nav-Bgsettings {
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 23px;
}
%nav-current {
  background-color: $gray-l;
}
$navRadius: 4px;

// ---------------------------------------

.c-global-navigation__list-parent {
  padding: 0.6em 9px;
  li a {
    &:hover {
      background: $gray-l;
    }
  }
  & .js-slide-toggle--collapse > .c-global-navigation__list-child {
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.c-global-navigation__list-child {
  padding: 0 0 1em 29px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.c-global-navigation__list-icon {
  display: block;
  border-radius: $navRadius;
  span {
    display: inline;
    line-height: 23px;
    padding-left: 4px;
  }
  &:before {
    content: '';
    display: inline-block;
    height: 23px;
    width: 23px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 23px;
    line-height: 23px;
    border-radius: $navRadius;
    vertical-align: bottom;
    @extend %nav-Bgsettings;
  }
}

@each $icontype in home, billing, thunder, results, analysis, comment, call,
  bulb, star, car, etc, point
{
  .c-global-navigation__list-icon--#{$icontype} {
    @extend .c-global-navigation__list-icon;
    &:before {
      background-image: url(~@images/v2/pc/nav-icon-off-#{$icontype}@2x.png);
    }
    &.c-global-navigation__list-parent__current {
      &:before {
        background-image: url(~@images/v2/pc/nav-icon-on-#{$icontype}@2x.png);
      }
    }
  }
}

.c-global-navigation__list-icon--home {
  &:before {
    @extend %nav-current;
  }
}

.c-global-navigation__list-parent__current {
  @extend %nav-current;
}

.c-global-navigation__list-child__current {
  color: $orange;
}

.c-global-navigation__list-parent__current--household {
  span {
    font-weight: 600;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 31px;
    background-image: url(~@images/v2/pc/nav-icon-current@2x.png);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 4px;
    position: absolute;
    left: -9px;
    top: 0;
  }
}

.c-global-navigation__family {
  @extend %border-bottom-default;
  h2 {
    padding: 1.4em 0 0 15px;
  }
}
.c-global-navigation__feature {
  @extend %border-bottom-default;
}
.c-global-navigation__mainnav {
  @extend %border-bottom-default;
}
.c-global-navigation__asidenav {
  border-bottom: none;
  li a {
    color: $orange;
    text-decoration: underline;
    &:hover {
      background: none;
    }
  }
}
