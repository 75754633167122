
.c-modal-target.is-long {
  & .c-modal-target__inner {
    max-width: 414px;
    height: 80%;
    &__element {
      height: 100%;
      border-radius: 8px;
      overflow-y: scroll;
    }
    &__footer {
      padding: 0;
      position: absolute;
      top: -30px;
      right: 85px;
      transform: translateX(100%);

      & .c-button--primary {
        padding: 0 30px 0 0;
        font-size: 18px;
        border: none;
        background: inherit;
        box-shadow: none;
        position: relative;

        &::after {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url(~@images/v2/pc/analysis/icon-close.png);
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}
