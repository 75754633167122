.c-results-graph,
.c-estimation-chart__container__graph {
  height: 350px;
}
.c-estimation-chart__container__graph {
  margin-top: 42px;
}
.c-graph-elm {
  width: 100%;
}
.c-graph-tab {
  height: 28px;
  margin: 0 0 1em 0 !important;
}
.c-graph-tab-switch {
  text-align: center;
  padding: 0 !important;
  li {
    display: inline-block;
    margin: 0 1px;
    background: $gray-l;
    padding: 0.5em 1.3em;
    font-weight: 100;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    &.current {
      background: $orange;
      color: $white;
      box-shadow: 0 2px 3px #ccc;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.c-graph-tab-target {
  margin-top: 2em;
  margin-bottom: 3em;
}
.c-graph-single {
  margin-top: 2em;
  margin-bottom: 3em;
}
.c-graph-single--comparison {
  @extend .c-graph-single;
  h2 {
    display: block;
    text-align: center;
    margin-top: -1.5em;
    margin-bottom: 1em;
    border-bottom: 1px solid $gray-l;
    padding-bottom: 1em;
  }
}
