.p-contract-applying {
  %__section-border {
    border-top: 1px solid $gray-l;
    padding-top: 3em;
  }
  &__indicator {
    padding: 40px 0 0 0;
    border-bottom: none;
    &__title {
      margin: 0 0 1em 0;
      font-size: 34px;
      color: $orange;
      font-weight: 400;
    }
    aside {
      @extend .u-center;
      padding: 1.6em 2em;
      line-height: 1.7;
      color: $gray;
      h3 {
        font-size: 14px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }
    }
  }
  &__opening-date {
    @extend %__section-border;
    &__date {
      display: block;
      margin: 40px 0;
      font-size: 20px;
    }
  }
  &__countdown {
    @extend %__section-border;
    padding: 0;
    border-bottom: none;
    &__overdue {
      color: $orange;
      margin: 1.6em 0;
    }
    &__date {
      font-size: 14px;
    }
    &__time-difference {
      margin: 30px 1.6em 0 1.6em;
      &__title {
        margin-top: 40px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
  &__availability {
    @extend %__section-border;
    ul {
      padding-left: 1.6em;
      margin: 30px 0 40px 0;
      li {
        line-height: 1.7;
      }
    }
  }
}
