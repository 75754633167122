body {
  font-family: 'Roboto', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN',
    'メイリオ', Meiryo, '游ゴシック', YuGothic, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
* {
  box-sizing: border-box;
}
p {
  line-height: 1.7;
  margin: 1.6em 0;
}
a {
  text-decoration: none;
  color: $gray-d;
  &:hover {
    opacity: 0.6;
  }
}
img {
  vertical-align: bottom;
}
@mixin paragraph-note {
  position: relative;
  padding-left: 1.2em;
  &::before {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 1em;
    content: '\203B';
  }
}
