.c-list--link {
  display: inline-block;
  li {
    display: inline-block;
    a {
      display: block;
      padding: 0 12px;
      border-right: 1px solid $gray-d;
      cursor: pointer;
    }
    &:last-child {
      a {
        border-right: none;
      }
    }
  }
}

//積みリスト
.c-list--aside {
  list-style: none;
  padding: 0 !important;
  margin-top: 1em !important;
  li {
    background: $gray-ll;
    font-size: 11px;
    padding: 1.6em 3em;
    margin-bottom: 2px;
    text-align: left;
    line-height: 1.7;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    p {
      margin: 0;
    }
  }
}

//積みリスト
.c-list--label {
  font-size: 13px;
  list-style: none;
  padding: 0 !important;
  margin-bottom: 3em !important;
  li {
    background: $gray-ll;
    border-radius: 4px;
    margin: 7px 0;
    position: relative;
    &.current,
    &:hover {
      background: $orange-l;
      a {
        color: $orange;
      }
    }
  }
  a {
    display: block;
    padding: 1em 0.8em 0.8em 0.8em;
    text-decoration: none !important;
    &:hover {
      background: none !important;
    }
  }
  i {
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 11px;
    margin-top: -0.8em;
    font-style: normal;
  }
}
.c-list--label--underline {
  font-size: 13px;
  list-style: none;
  padding: 0 !important;
  margin-bottom: 3em !important;
  font-weight: 600;
  li {
    background: none;
    border-bottom: 1px solid $gray-l;
    margin: 0;
    position: relative;
    line-height: 1.7;
    &:first-child {
      border-top: 1px solid $gray-l;
    }
    &.current,
    &:hover {
      background: $orange-l;
      a {
        color: $orange;
      }
    }
  }
  .c-icon--new {
    top: 18px;
    font-size: 12px;
  }
  .c-icon--day {
    bottom: 13px;
  }
  .c-icon--new,
  .c-icon--day {
    position: absolute;
    right: 0;
  }
  a {
    width: 250px;
    display: block;
    padding: 2em 0.8em 1.8em 0.8em;
    text-decoration: none !important;
    &:hover {
      background: none !important;
    }
  }
}

//積みリスト用ヘッダ
.c-list--label-head {
  display: block;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 0.5em;
  background: $gray-ll;
  color: $gray;
  margin: 1.5em 0 0 0;
  position: relative;
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(/images/v2/pc/icon-arrow-dn.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -6px;
  }
  &:hover {
    background: $gray-l;
  }
  &.selected {
    &:after {
      background-image: url(/images/v2/pc/icon-arrow-up.png);
    }
  }
  & + .c-list--label--underline {
    margin-top: 0;
    li:first-child {
      border-top: none;
    }
  }
}

//積みリスト-フルサイズ
.c-list--label--underline-full {
  margin-top: 0 !important;
  @extend .c-list--label--underline;
  li:first-child {
    border-top: none;
  }
  li:nth-child(even) {
    background: $orange-ll;
  }
  .c-icon--new {
    top: 14px;
    font-size: 12px;
  }
  .c-icon--day {
    bottom: 9px;
  }
  .c-icon--new,
  .c-icon--day {
    position: absolute;
    right: 0;
  }
  a {
    width: 90%;
    display: block;
    padding: 1.6em 0.8em 1.4em 0.8em;
    text-decoration: none !important;
    &:hover {
      background: none !important;
    }
  }
}
.c-list--label-photolist {
  list-style: none;
  padding: 0 !important;
  margin-top: -3em !important;
  margin-bottom: -3em !important;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 !important;
    li {
      display: inline-block;
    }
  }
  .image {
    @include fleft;
    margin-right: 10px;
  }
  .title {
    font-weight: 400;
    text-decoration: none;
    width: 85%;
    display: block;
    margin-bottom: 1.6em;
  }
  .c-icon--new,
  .c-view-count {
    position: absolute;
    right: 0;
  }
  .c-icon--new {
    top: 19px;
  }
  .c-view-count {
    bottom: 15px;
  }
  > li {
    position: relative;
    border-bottom: 1px solid $gray-l;
    @include clf;
    padding: 15px 0;
    &:last-child {
      border-bottom: none;
    }
  }
}
