.p-topic {
  &__detail {
    ul {
      padding-left: 1.6em;
      margin: 1.6em 0;
      li {
        line-height: 1.7;
      }
    }
    & > img {
      width: 100%;
      margin: 0px auto 30px auto;
    }
    &__note {
      @include paragraph-note;
    }
  }
  &__link {
    margin-top: -26px;
    text-align: right;
    > a {
      font-size: 13px;
      cursor: pointer;
      &:hover {
        background-color: initial;
        opacity: 0.6;
      }
    }
  }
}
