.c-table {
  width: 100%;
  border: 1px solid $gray-l;
  font-size: 12px;
  margin-bottom: 2em;
  th {
    background: $orange-l;
    width: 160px;
  }
  th,
  td {
    border: 1px solid $gray-l;
    text-align: left;
    padding: 1em 0.8em;
  }
}
.c-table--charge-detail__small {
  font-size: 12px;
}
.c-table--charge-detail {
  width: 100%;
  tr {
    border-bottom: 1px solid $gray-l;
  }
  thead {
    font-size: 12px;
    th,
    td {
      padding: 0 0 0.7em 0;
    }
  }
  tbody {
    font-size: 18px;
    th,
    td {
      padding: 1em 0;
    }
    tr {
      td {
        text-align: center;
      }
      td:last-child {
        text-align: right;
      }
    }
  }
  &__forecast {
    color: #54aeea;
  }
}
.c-table--billing {
  width: 100%;
  margin-bottom: 3em;
  tr {
    border-bottom: 1px solid $gray-l;
    &:first-child {
      border-top: 1px solid $gray-l;
    }
    td {
      padding: 1.4em 0 1.2em 0;
      &:first-child {
        text-align: left;
        width: 50%;
      }
      &:last-child {
        text-align: right;
        width: 50%;
      }
    }
  }
}
.c-table--comparison-details {
  width: 100%;
  font-size: 16px;
  text-align: center;
  tr {
    border-bottom: 1px solid $gray-l;
    &:last-of-type {
      border-bottom: none;
    }
  }
  th,
  td {
    width: 50%;
    padding: 1em 0;
  }
}
