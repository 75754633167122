.p-billing {
  .c-button-wrap {
    font-size: 14px;
    margin-top: 1em;
    margin-bottom: -0.8em;
    a {
      text-decoration: none;
      margin: 0;
      &:hover {
        background: none;
        opacity: 0.6;
      }
    }
  }
  aside {
    a {
      cursor: pointer;
      color: #eb7d05;
      text-decoration: underline;
      &:hover {
        background: none;
        opacity: 0.6;
      }
    }
  }
}
