.l-document-side {
  width: 300px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 2em;
  .c-search-box {
    margin-bottom: 3em;
  }
}
