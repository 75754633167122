.u-txt-bold {
  font-weight: 600;
}
.u-txt-smaller {
  font-size: 11px;
  color: $gray;
}
.u-txt-red {
  color: $red !important;
}
.u-txt-blue {
  color: $blue !important;
}
.u-txt-orange {
  color: $orange !important;
}
.u-txt-underline {
  text-decoration: underline;
}
.u-txt-no-decoration {
  text-decoration: none;
}
