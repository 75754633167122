.c-opt-out__header {
  color: #eb7d05;
}
.c-opt-out__table tr th,
.c-opt-out__table tr td {
  text-align: center;
  vertical-align: middle;
  line-height: 1.4;
}
.c-opt-out__table tr th {
  background-color: transparent;
}
.c-opt-out__table tr td a {
  text-decoration: underline;
  color: #eb7d05;
}
