@use 'sass:math';

$c: '.c-appliances-chart';
$colors: (
  etc: $gray-etc,
  fridge: $red-d,
  ac: $blue,
  light: $green,
  tv: $yellow-d,
);

#{$c} {
  padding-top: 56px;
  &__container {
    height: 363px;
    margin-bottom: 50px;
    border-bottom: 1px solid $gray-l;
    &::after {
      content: '';
      display: block;
      clear: both;
    }
    &__total,
    &__graph,
    &__legend {
      float: left;
      height: 100%;
      width: percentage(math.div(1, 3));
    }
    &__total {
      position: relative;
      &__score {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        &__label {
          font-weight: 600;
          color: $gray-d;
        }
        &__cost {
          height: 1.5em;
          line-height: 1.5;
          word-break: keep-all;
          color: $orange;
          font-size: 53px;
          font-weight: 100;
          & span {
            font-size: 16px;
          }
        }
        &__usage {
          color: $gray;
          font-size: 18px;
          font-weight: 100;
          & span {
            margin-left: 0.2em;
            font-size: 14px;
          }
        }
      }
    }
    &__legend {
      font-weight: 300;
      &__list {
        height: 20%;
        &::after {
          content: '';
          display: block;
          clear: both;
        }
        & dt,
        dd {
          position: relative;
          float: left;
          width: 50%;
          padding: 5px 0;
          white-space: nowrap;
          & span {
            margin-left: 0.2em;
            font-size: 75%;
          }
        }
        & dt {
          padding-left: 1.6em;
          text-align: left;
        }
        & dd {
          text-align: right;
        }
        $dl: #{&};
        $dl-name: &;
        @each $name, $color in $colors {
          &--#{$name} {
            @extend #{$dl};
            & #{$dl-name}__label {
              font-weight: 600;
              color: $gray-d;
              &::before {
                content: '';
                position: absolute;
                display: inline-block;
                top: 50%;
                -webkit-transform: translate(-1.6em, -50%);
                -ms-transform: translate(-1.6em, -50%);
                transform: translate(-1.6em, -50%);
                width: 1em;
                height: 1em;
                border-radius: 50%;
                background-color: #{$color};
              }
            }
            & #{$dl-name}__percentage,
            #{$dl-name}__cost {
              color: #{$color};
            }
            & #{$dl-name}__cost {
              font-size: 18px;
            }
            & #{$dl-name}__usage {
              color: $gray;
            }
          }
        }
      }
    }
  }
}
