.c-calendar {
  width: 273px;
  text-align: center;
  color: $gray;
  $calendar: #{&};
  tr {
    border-bottom: 1px solid $gray-l;
  }
  thead {
    font-size: 11px;
    th {
      padding: 4px;
      &:nth-child(7) {
        color: $red;
      }
      &:nth-child(6) {
        color: $blue;
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        border-bottom: none;
      }
    }
    td {
      &:hover {
        .c-calendar__mask {
          opacity: 0.4;
        }
      }
      height: 39px;
      vertical-align: middle;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
  &--disable {
    @extend #{$calendar};
    opacity: 0.5;
  }
}

.c-calendar__title {
  font-size: 16px;
  margin: 0 0 0.8em 0;
  text-align: center;
}
.c-calendar__body__week {
  display: none;
  &--show {
    display: table-row;
  }
  &__date {
    &--invalid {
      opacity: 0.3;
    }
    &--saturday {
      color: $blue;
    }
    &--holiday,
    &--sunday {
      color: $red;
    }
  }
}

.c-calendar__mask {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  height: 32px;
  $mask: #{&};
  &--day {
    @extend #{$mask};
    border-radius: 100%;
    width: 32px;
    left: 3px;
  }
  &--isoWeek {
    @extend #{$mask};
    width: 100%;
  }
  z-index: 1;
}
.c-calendar__on-green {
  background: $green-o;
  cursor: pointer;
  border-radius: 100%;
  width: 32px;
  left: 3px !important;
}
.c-calendar__on-green-full {
  background: $green-o;
  cursor: pointer;
  width: 100%;
}
.c-calendar__on-green-full-s {
  background: $green-o;
  cursor: pointer;
  width: 100%;
  border-radius: 16px 0 0 16px;
}
.c-calendar__on-green-full-e {
  background: $green-o;
  cursor: pointer;
  width: 100%;
  border-radius: 0 16px 16px 0;
}

.c-calendar__body__week__date {
  cursor: pointer;
  & .num {
    position: relative;
    display: block;
    z-index: 2;
  }
  &--target {
    & .c-calendar__mask--day,
    .c-calendar__mask--isoWeek {
      background: $orange-o;
    }
    &:first-of-type {
      & .c-calendar__mask--isoWeek {
        border-radius: 16px 0 0 16px;
      }
    }
    &:last-of-type {
      & .c-calendar__mask--isoWeek {
        border-radius: 0 16px 16px 0;
      }
    }
  }
  &--selected {
    @extend .c-calendar__body__week__date--target;
  }
}

.c-calendar-wrap {
  position: relative;
  width: 273px;
  margin: 0 auto;
  .slick-arrow {
    position: absolute;
    top: -6px;
    text-indent: -9999px;
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    z-index: 12;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 5px;
    &:hover {
      opacity: 0.6;
    }
  }
  .slick-disabled {
    display: none;
  }
  .slick-prev {
    left: 0;
    background-image: url(~@images/v2/pc/icon-arrow-prev.png);
  }
  .slick-next {
    right: 0;
    background-image: url(~@images/v2/pc/icon-arrow-next.png);
  }
}
.c-calendar-wrap__elm {
  width: 273px;
}
