.c-pager {
  list-style: none;
  padding: 0;
  margin: 2em 0;
  text-align: center;
  line-height: 1;
  font-size: 14px;
  li {
    display: inline-block;
    border: 1px solid $gray-l;
    line-height: 1 !important;
    a {
      display: block;
      padding: 0.6em 0.8em;
      text-decoration: none;
    }
    &.c-pager__prev {
      margin-right: 2em;
      a {
        padding: 0.6em 1.2em;
      }
    }
    &.c-pager__next {
      margin-left: 2em;
      a {
        padding: 0.6em 1.2em;
      }
    }
    &:hover {
      background: $orange-l;
    }
    &.c-pager__current {
      background: $orange;
      color: $white;
      a {
        color: $white;
      }
    }
  }
}
