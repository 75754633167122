@use 'sass:math';
$c: '.c-comparison-chart';

#{$c} {
  padding-top: 56px;
  &__container {
    height: 363px;
    margin-bottom: 8px;
    border-bottom: 1px solid $gray-l;
    &__household {
      padding: 0 percentage(math.div(0.6, 3.8)); //グラフ全体幅に対してのgraphMargin
      height: 25%;
      width: 100%;
      &::after {
        content: '';
        display: block;
        clear: both;
      }
      &__column {
        position: relative;
        float: left;
        width: percentage(math.div(0.6, 2.6));
        height: 100%;
        &:nth-of-type(2) {
          margin: 0 percentage(math.div(0.4, 2.6));
        }
        &__score {
          position: absolute;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          text-align: center;
          $score: #{&};
          &--top {
            @extend #{$score};
            color: $green;
          }
          &--your {
            @extend #{$score};
            color: $orange;
          }
          &--all {
            @extend #{$score};
            color: $blue-l;
          }
          & dt,
          dd {
            white-space: nowrap;
            & span {
              padding-left: 0.2em;
              font-size: 75%;
            }
          }
          & dd {
            font-weight: 300;
          }
          &__label {
            font-size: 16px;
          }
          &__cost {
            font-size: 20px;
            line-height: 2;
          }
          &__usage {
            font-size: 14px;
            color: $gray-d;
          }
        }
      }
    }
    &__graph {
      float: left;
      height: 75%;
      width: 100%;
    }
  }
}
