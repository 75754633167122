.u-link {
  opacity: 1;
  cursor: pointer;
  $link: #{&};
  &--enabled {
    @extend #{$link};
    $enabled: #{&};
    &:hover {
      @extend #{$enabled};
      opacity: 0.6;
    }
  }
  &--disabled {
    opacity: 0.3;
    cursor: default;
    box-shadow: none;
    $disabled: #{&};
    &:hover {
      @extend #{$disabled};
    }
  }
}
