//body
#l-wrap {
  @include clf;
}

.c-document-wrap {
  margin: 0 26px;
  @include clf;
  padding-right: 326px;
  position: relative;
  //   @extend .c-document-base;
}

.c-document-wrap--full {
  @extend .c-document-wrap;
  margin: 0 auto;
  padding: 0 26px;
}

//インナー
.c-document-inner {
  @extend .c-document-wrap;
  @extend .c-document-base;
  margin: 0 auto;
  padding: 0 26px;
  max-width: 700px;
}
