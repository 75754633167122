.c-modal-target {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.66);
  font-size: 16px;
}
.c-modal-target__inner {
  max-width: 780px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 8px;
  background: $white;
  &__small {
    @extend .c-modal-target__inner;
    width: 50%;
    max-width: 400px;
    padding-top: 60px;
    .c-contact-box {
      margin-bottom: 10px;
    }
  }
  &__footer {
    padding: 1em 1.6em;
    text-align: right;
  }
}
.c-modal-target__inner__element {
  width: 100%;
  header {
    @extend %border-bottom-default;
    text-align: center;
    padding: 1.4em 1.6em;
  }
  &__note {
    width: 100%;
    height: 11%;
    max-height: 60px;
    margin: 1em 0 0 0;
    font-size: 12px;
  }
}
.c-modal-target__inner__element__body {
  @extend .c-document-base;
  max-height: 600px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 1em 1.6em;
  @extend %border-bottom-default;
  ul {
    padding-left: 1.6em;
    margin: 1.6em 0;
    li {
      line-height: 1.7;
    }
  }
}
