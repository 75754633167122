.c-icon {
  display: inline-block;
  line-height: 1;
  color: $orange;
}
.c-icon--new {
  @extend .c-icon;
  font-size: 13px;
  border: 1px solid $orange;
  border-radius: 3px;
  padding: 2px 0.3em;
  margin-right: 5px;
}
.c-icon--label {
  @extend .c-icon;
  font-size: 11px;
  background: $orange-l;
  text-align: center;
  margin: 0 2px;
  border-radius: 2px;
  padding: 4px 1em;
  min-width: 6em;
}
.c-icon--day {
  @extend .c-icon;
  font-size: 12px;
  border-radius: 3px;
  padding: 2px 0;
  margin-right: 5px;
  font-weight: 400;
}

.c-icon-area {
  text-align: center;
  padding-top: 2em;
  margin-bottom: -2.4em;
  ul {
    list-style: none;
    margin: 0.6em;
    padding: 0;
    li {
      display: inline-block;
    }
  }
}
