.c-view-count {
  display: inline-block;
  border: 1px solid $white;
  color: $gray;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-bottom: -4px;
    margin-right: 3px;
    background-image: url(/images/v2/pc/icon-eye.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center center;
  }
}
