.p-help {
  &__section {
    margin-top: 60px;

    &__comment {
      margin-bottom: 20px !important;
      line-height: 1.7;

      &__example {
        margin-top: 10px !important;
        padding-left: 2em;
        position: relative;

        &:before {
          content: '例）';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
