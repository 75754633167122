%c-external-base {
  border: 1px solid #95989a;
  border-radius: 8px;
  padding: 1.6em;
}
.c-external-link {
  @extend %c-external-base;
  @include clf;
  .image {
    display: inline-block;
    margin-right: 1.6em;
    @include fleft;
    width: 50%;
    max-width: 139px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.c-external-link__title {
  font-size: 16px;
  small {
    display: block;
    margin-top: 1em;
    font-weight: 400;
  }
}
.c-external-link__image {
  @extend %c-external-base;
  padding-bottom: 0.8em;
  @include clf;
  .image {
    display: inline-block;
    width: 100%;
    margin-bottom: 0.8em;
    img {
      width: 100%;
      height: auto;
    }
  }
  .c-icon--withfavi {
    text-align: right;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.c-external-link__text {
  .c-icon--withfavi {
    display: inline-block;
    line-height: 18px;
    margin: 0;
    vertical-align: top;
  }
  a {
    display: inline-block;
    line-height: 18px;
    vertical-align: top;
  }
  small {
    display: inline-block;
    font-size: 10px;
    color: $gray;
    margin: 0 0.6em;
    line-height: 18px;
    vertical-align: top;
  }
}
.c-icon--withfavi {
  font-size: 11px;
  line-height: 18px;
  margin: 1.6em 0;
  a {
    text-decoration: none !important;
  }
  img {
    margin-right: 6px;
  }
}
