$black: #333
$white: #fff
$red: #f00


$orange-fff2dc: #fff2dc
$orange-fff4e8: #fff4e8
$thin-orange: #fdecd2
$light-orange: #fadfb1
$orange-ffa200: #ffa200
$orange: #f29600
$orange-f18000: #f18000
$orange-f38500: #f38500
$orange-ed7001: #ed7001
$orange-ed7201: #ed7201
$orange-eb5402: #eb5402
$orange-f15c2a: #f15c2a
$orange-e05248: #e05248
$dark-orange: #ec6c00
$brand-orange: #eb5505

$transparent-orange: rgba(255, 165, 0, 0.2)

$gray-c7c8c9: #c7c8c9
$almost-white-gray: #f6f6f6
$ultra-light-gray-efefef: #efefef
$ultra-light-gray-e9e9e9: #e9e9e9
$ultra-light-gray: #e6e6e6
$light-gray-aaa: #aaa
$light-gray-bbb: #bbb
$light-gray-ccc: #ccc
$light-gray-ddd: #ddd
$light-gray-eee: #eee
$light-gray: #c8c9ca
$gray-d7ccc0: #d7ccc0
$gray-8b8b8b: #8b8b8b
$gray-b8babc: #b8babc
$gray-f7f7f7: #f7f7f7
$gray-f0f0f0: #f0f0f0
$gray: #808080
$dark-gray: #606060
$dark-gray-4f4b49: #4f4b49

$transparent: rgba(0,0,0,0)
$background-dark: rgba(0,0,0,0.4)

$blue: #2196f3
$blue-93acad: #93acad
$light-blue-f5fbff: #f5fbff
$light-blue-edf7ff: #edf7ff
$yellow: #f9d200
$green: #8dc21f
$green-98c828: #98c828
$red-d92323: #d92323
$red-de0000: #de0000
$dark-red: #de0a0a
$turquoise: #29b5b2
$dark-blue: #198ccb
$pink: #ffdede
$pink-fff0f0: #fff0f0
$chiffon: #fcebdd
$cream-fffcf4: #fffcf4
$navy-384e5c: #384e5c

$badge_color: $red

.fill-gray
  fill: $gray

.fill-gray-8b8b8b
  fill: $gray-8b8b8b

.fill-light-gray
  fill: $light-gray

.fill-orange
  fill: $orange

.fill-orange-f18000
  fill: $orange-f18000
.fill-green
  fill: $green

.fill-white
  fill: $white

.fill-red
  fill: $red

.fill-turquoise
  fill: $turquoise

.fill-chiffon
  fill: $chiffon

.stroke-orange
  stroke: $orange
