
@import '@sass/v2/pc/foundation/_color.scss';

.c-modal-button {
  border: none;
  margin: 0;
  color: $white;
  background: $dark-orange;
  border-radius: 2px;
  box-shadow: 0 2px 2px $gray-bs;
  display: inline-block;
  padding: 10px 18px;
  font-size: 19px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 10px $gray-bs;
    background: $dark-orange;
  }
}
