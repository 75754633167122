.l-document-footer {
  margin: 50px 26px 0 26px;
  font-size: 10px;
  color: $gray-d;
  position: relative;
  ul {
    @extend .c-list--link;
  }
}

.l-document-footer__logo {
  line-height: 70px;
  display: inline-block;
  img {
    vertical-align: middle;
    width: auto;
    height: 27px;
  }
}

.l-document-footer__page-top {
  display: block;
  position: fixed;
  bottom: 80px;
  right: 26px;
}

.l-document-footer__copy-right {
  letter-spacing: 0.05em;
  line-height: 60px;
  display: inline-block;
}
