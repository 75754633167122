.c-tab-title {
  @include clf;
  border-bottom: 1px solid $gray-l;
  position: relative;
  height: 45px;
  color: $gray;
  margin-top: 2em;
  margin-bottom: 4em;
  @media screen and (max-width: 1300px) {
    margin-top: 4em;
  }
  &__text {
    font-size: 27px;
    font-weight: 400;
    line-height: 43px;
    margin: 0;
    padding: 0;
    span {
      font-size: 14px;
    }
  }
  &__tab {
    margin: 0;
    padding: 0;
    list-style: none;
    a {
      display: block;
      font-size: 14px;
      padding: 1.1em 1em;
      &:hover {
        opacity: 1 !important;
      }
    }
    li {
      display: inline-block;
      font-weight: 400;
      border-radius: 8px 8px 0 0;
      &.c-tab-title__tab__current {
        background: $orange;
        a {
          color: $white;
        }
        &:hover {
          background: $orange;
          color: $white;
        }
      }
      &:hover {
        background: $orange-l;
      }
    }
  }
}
.c-document-wrap--full .c-tab-title__tab {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  li {
    line-height: 1;
    a {
      text-decoration: none;
      border-radius: 8px 8px 0 0;
    }
  }
}
