.p-classic-top {
  header {
    aside {
      padding: 1.6em 3em;
      line-height: 1.7;
      color: $gray;
      h3 {
        font-size: 14px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }
    }
  }
  &__date {
    margin: 1em 0;
    font-size: 14px;
  }
  &__about-use {
    margin: 3em 1.6em 0 1.6em;
    text-align: left;
    &__title {
      margin-top: 40px;
      font-size: 18px;
      text-align: center;
    }
  }
  &__note {
    border-top: 1px solid $gray-l;
    &__heading {
      margin-top: 3em;
      font-size: 14px;
      color: $gray;
    }
  }
}
