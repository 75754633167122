
$gray: #eaeaea;

.l-app-banner-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 166px;
  background-color: $gray;
  z-index: 100;
  text-align: center;

  img {
    width: auto;
    height: 166px;
  }

  &--open {
    position: absolute;
    top: 20px;
    left: 66%;
    width: 210px;
    height: 128px;
  }

  &--close {
    position: absolute;
    top: 43px;
    left: 85%;
    width: 80px;
    height: 80px;
  }
}
