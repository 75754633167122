.c-household-label {
  background: $orange-l;
  color: $orange;
  display: block;
  font-size: 14px;
  padding: 0.8em 0;
  text-align: center;
  img {
    vertical-align: inherit;
  }
}

.c-household-label--big {
  display: inline-block;
  margin: 3em 0;
  dl {
    @include clf;
    cursor: pointer;
    margin-bottom: 8px;
    &:hover {
      opacity: 0.6;
    }
    dt,
    dd {
      @include fleft;
    }
    dd {
      color: $gray;
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
      padding-top: 15px;
      text-align: left;
      small {
        display: block;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 2px;
      }
    }
  }
  &__badge {
    border: none;
    border-radius: 12px;
    color: $white;
    display: block;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.5em;
    padding: 0 6px;
    text-align: center;
    width: 5em;
    $batch: #{&};
    &--active {
      @extend #{$batch};
      background: $orange;
    }
    &--inactive {
      @extend #{$batch};
      background: $gray;
    }
  }
}
.c-household-label--big__icon {
  border: 5px solid rgba(255, 255, 255, 0);
  border-radius: 12px;
  img {
    vertical-align: bottom;
  }
}

.c-household-label--big__icon--last-selected {
  border: 5px solid $orange-l;
  border-radius: 9px;
}
.c-household-label--big__icon--selected {
  border: 5px solid $orange;
  border-radius: 9px;
}
