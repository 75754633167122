.c-document-title {
  font-size: 34px;
  color: $orange;
  font-weight: 400;
  margin: 0 0 2em 0;
  span {
    line-height: 1.5;
    display: block;
    font-size: 16px;
    color: $gray;
    margin-top: 1em;
  }
}
.c-document-title--main {
  font-size: 26px;
  font-weight: 400;
  color: $gray;
  line-height: 1.3;
  span {
    display: block;
    small {
      color: $orange;
      font-size: 11px;
    }
  }
}
.c-document-title--middle {
  font-size: 20px;
  font-weight: 600;
  color: $gray;
  margin-bottom: 1em;
}
.c-document-title--large {
  font-size: 26px;
  font-weight: 400;
  border-top: 1px solid $gray-l;
  border-bottom: 1px solid $gray-l;
  text-align: center;
  line-height: 1.6;
  padding: 0.3em 0;
  margin-bottom: 1.3em;
  margin-top: 3em;
  color: $gray;
}
.c-document-title--small {
  font-size: 16px;
  font-weight: 600;
  color: $black-l;
  position: relative;
  margin-bottom: 1.5em;
  margin-top: 3em;
  &:before {
    content: '';
    display: block;
    height: 4px;
    width: 9px;
    border-radius: 30px;
    background: $orange;
    position: absolute;
    left: -18px;
    top: 4px;
  }
}
.c-document-title--small--gray {
  @extend .c-document-title--small;
  &:before {
    background: $gray-l;
  }
}
.c-document-title--side-medium {
  font-size: 14px;
  color: $gray;
}
.c-document-title--side-search {
  font-size: 14px;
  color: $gray;
  padding-left: 24px;
  background-image: url(/images/v2/pc/icon-search.png);
  background-position: left center;
  background-size: 22px;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}
