.c-document-category {
  text-align: center;
  margin-top: 2em;
  margin-bottom: -2.5em;
  ul {
    margin: 1em 0 !important;
    padding: 0 !important;
    list-style: none;
    li {
      display: inline-block;
    }
  }
}
