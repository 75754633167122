.p-login-caution {
  .c-document-inner {
    header {
      padding-bottom: 0;
      border-bottom: none;
    }
    a {
      cursor: pointer;
    }
  }
}
