.c-area-info {
  background: $orange-l;
  color: $orange;
  @include fright;
  width: 290px;
  @include clf;
  padding: 13px 10px 9px 10px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: $bp-mid) {
    width: 100%;
    float: none;
    text-align: center;
    padding: 6px 0 6px 230px;
  }
}
.c-area-info__left {
  font-size: 51px;
  @include fleft;
  @media screen and (max-width: $bp-mid) {
    font-size: 20px;
    display: inline-block;
    float: none;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $orange;
  }
}
.c-area-info__left__year-week {
  font-size: 12px;
  font-weight: 200;
  margin-bottom: 3px;
  @media screen and (max-width: $bp-mid) {
    display: inline-block;
    padding: 0 5px;
  }
}
.c-area-info__right {
  @include fright;
  text-align: right;
  @media screen and (max-width: $bp-mid) {
    display: inline-block;
    float: none;
    text-align: left;
    padding: 0 15px;
  }
}
.c-area-info__right__household {
  font-size: 12px;
  margin-bottom: 0.6em;
  @media screen and (max-width: $bp-mid) {
    display: inline-block;
    margin-bottom: 0;
  }
}
.c-area-info__right__weather {
  font-size: 12px;
  margin: 0.5em 0 0 0;
  line-height: 20px;
  &:before {
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-bottom: -4px;
    margin-right: 3px;
    background-size: 20px;
    background-position: left top;
    background-repeat: no-repeat;
    @media screen and (max-width: $bp-mid) {
      margin-bottom: -3px;
    }
  }
  @media screen and (max-width: $bp-mid) {
    display: inline-block;
    margin: 0;
    padding: 0 15px;
  }
}
@each $i
  in (
    100,
    101,
    102,
    103,
    104,
    105,
    106,
    107,
    108,
    110,
    111,
    112,
    113,
    114,
    115,
    116,
    117,
    118,
    119,
    120,
    121,
    122,
    123,
    124,
    125,
    126,
    127,
    128,
    129,
    130,
    131,
    132,
    140,
    160,
    170,
    181,
    200,
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    209,
    210,
    211,
    212,
    213,
    214,
    215,
    216,
    217,
    218,
    219,
    220,
    221,
    222,
    223,
    224,
    225,
    226,
    227,
    228,
    229,
    230,
    231,
    240,
    250,
    260,
    270,
    281,
    300,
    301,
    302,
    303,
    304,
    306,
    308,
    309,
    311,
    313,
    314,
    315,
    316,
    317,
    320,
    321,
    322,
    323,
    324,
    325,
    326,
    327,
    328,
    329,
    340,
    350,
    361,
    371,
    400,
    401,
    402,
    403,
    405,
    406,
    407,
    409,
    411,
    413,
    414,
    420,
    421,
    422,
    423,
    424,
    425,
    426,
    427,
    430,
    450,
    500,
    550,
    552,
    553,
    558,
    562,
    563,
    568,
    572,
    573,
    582,
    583,
    600,
    800,
    850,
    851,
    852,
    853,
    854,
    855,
    859,
    861,
    862,
    863,
    864,
    865,
    869,
    871,
    872,
    873,
    874,
    881,
    882,
    883,
    884,
    950,
    951,
    952,
    953,
    954,
    958,
    961,
    962,
    963,
    964,
    968,
    971,
    972,
    973,
    974,
    981,
    982,
    983,
    984
  )
{
  .c-area-info__right__weather__icon--#{$i} {
    @extend .c-area-info__right__weather;
    &:before {
      background-image: url(/images/v2/pc/wether-icon-#{$i}@2x.png);
    }
  }
}
.c-area-info__right__temperature {
  font-size: 12px;
  span {
    font-size: 20px;
    font-weight: 200;
    margin: 0 2px;
  }
  @media screen and (max-width: $bp-mid) {
    display: inline-block;
  }
}
