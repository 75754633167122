.c-nav-select-date {
  position: absolute;
  bottom: -36px;
  right: 0;
  a {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
  }
  span {
    display: inline-block;
    font-size: 14px;
    color: $gray;
    font-weight: 400;
    padding-left: 22px;
    background-image: url(~@images/v2/pc/icon-cal.png);
    background-size: 18px;
    background-repeat: no-repeat;
    line-height: 20px;
    background-position: left top;
    margin: 0 10px 0 15px;
    &:hover {
      opacity: 0.6;
    }
  }
}

.c-nav-select-date-box {
  position: absolute;
  top: 101px;
  right: 0;
  border: 2px solid $orange;
  border-radius: 7px;
  z-index: 10;
  background: $white;
  padding: 18px 0 8px 0;
  width: 300px;
  box-shadow: 0 2px 4px #bbbbbb;
  &:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    background: $white;
    border: 2px solid $orange;
    position: absolute;
    top: -8px;
    right: 83px;
    transform: rotate(-45deg);
  }
  &:after {
    content: '';
    display: block;
    height: 18px;
    width: 100%;
    background: $white;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 7px;
  }
}
.c-calendar__title {
  text-align: center;
  font-size: 16px;
  margin: 0 0 0.8em 0;
}
