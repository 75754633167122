$gray-d: #5b5758;
$gray: #777777;
$gray-l: #e2e3e4;
$gray-ll: #f7f7f7;
$gray-bs: #bbbbbb;
$gray-etc: #cbc3c3;
$black-l: #666666;
$black: #333333;
$white: #ffffff;
$orange: #eb7d05;
$orange-l: #fbead6;
$orange-ll: #fffaf6;
$dark-orange: #ec6c00;
$orange-o: rgba(226, 135, 43, 0.5);
$green: #87ba35;
$green-o: rgba(144, 191, 67, 0.5);
$blue: #54aeea;
$blue-l: #a8d5f4;
$red: #e70000;
$red-d: #bf0707;
$yellow-d: #eab504;

$green_wow: #00c3aa;
$green_wow-l: #addfd8;
