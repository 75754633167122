.c-contact-box {
  margin-bottom: 2em;
  div {
    margin-bottom: 0.4em;
    line-height: 1.7;
    font-size: 18px;
    font-weight: 400;
    color: $orange;
    span {
      display: block;
      margin: 0.6em 0;
      font-size: 12px;
      color: $gray;
    }
  }
  dt {
    display: inline-block;
  }
  dd {
    display: inline-block;
  }
  &__title {
    font-weight: 600;
    font-size: 19px;
    color: $dark-orange;
    &--catv {
      margin: 40px 0 20px 0;
      font-weight: 600;
      &.title-first {
        margin-top: 0;
      }
    }
  }
  &__text {
    margin: 10px 0;
    font-size: 12px;
    color: $gray-d;
  }
  &__comment {
    margin: 0;
    font-size: 14px;
  }
  &__number {
    margin: 0;
    line-height: 1;
    font-size: 13px;
    font-weight: 600;
    span {
      margin: 0 12px;
      font-size: 30px;
      color: $dark-orange;
      font-weight: 200;
    }
  }
  &__time {
    margin-top: 0.6em;
    line-height: 1;
    font-size: 12px;
    font-weight: 400;
  }
}
