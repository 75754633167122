.l-side-area {
  overflow-y: auto;
  width: 230px;
  float: left;
  font-size: 14px;
  position: fixed;
  top: 88px;
  left: 0;
  z-index: 110;
  background: $white;
  ul {
    li {
      margin: 0.3em 0;
      position: relative;
      border-radius: 4px;
      a {
        padding: 0.3em 0.4em;
        display: block;
        border-radius: 4px;
      }
    }
  }
}
