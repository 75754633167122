.c-footer-banner {
  margin: 50px 0;
  ul {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    li {
      width: 33%;
      display: inline-block;
      vertical-align: top;
      //for develop
      a {
        height: 120px;
        width: 240px;
        background: #eee;
        display: inline-block;
      }
    }
  }
}
