.l-login-header {
  text-align: center;
  width: 100%;
  padding: 1em 0;
  margin: 0 0 6em 0;
  border: none;
  &__title {
    & > img {
      height: 40px;
    }
  }
}
