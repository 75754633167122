.c-button {
  border: none;
  margin: 0;
  color: $white;
  background: $orange;
  border-radius: 2px;
  box-shadow: 0 2px 2px $gray-bs;
  display: inline-block;
  padding: 1em 1.6em;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
    background: $orange;
  }
  $button: #{&};
  &--primary {
    @extend #{$button};
    $primary: #{&};
    &--xs {
      @extend #{$primary};
      padding: 0.4em 0.8em;
      line-height: 1;
      vertical-align: middle;
      margin: -0.6em 0.3em 0 0.3em;
    }
  }
  &--secondary {
    @extend #{$button};
    color: $orange;
    background: none;
    box-shadow: none;
    &:hover {
      opacity: 0.6;
      background: none;
    }
  }
  &--wow {
    @extend #{$button};
    background: $green_wow;
    &:hover {
      background: $green_wow-l !important;
    }
  }
}

.c-button-wrap {
  margin-bottom: 2em;
  a {
    margin: 0.6em 1em;
  }
}
