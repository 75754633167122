.c-document-base {
  font-size: 16px;
  header {
    text-align: center;
    margin: 4.5em 0 0 0;
    border-bottom: 1px solid $gray-l;
    padding: 2em 0;
    & + hr {
      border: none;
    }
  }
  footer {
    margin: 3em 0 0 0;
    border-top: 1px solid $gray-l;
    padding: 2em 0;
    position: relative;
    @include clf;
  }

  a {
    &:hover {
      background: $orange-l;
      opacity: 1;
    }
  }

  .c-hr {
    margin-bottom: 3em;
  }

  aside {
    background: $gray-ll;
    border-radius: 4px;
    font-size: 11px;
    padding: 1.6em 3em;
    margin-bottom: 3em;
    text-align: left;
    p {
      margin: 0;
    }
  }
  strong {
    color: $orange;
    text-decoration: underline;
    font-weight: 400;
  }
}
