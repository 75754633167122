.c-photo-module {
  text-align: center;
  margin-bottom: 2em;
  img {
    display: inline-block;
    width: 100%;
    max-width: 600px;
    height: auto;
  }
}
