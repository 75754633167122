.c-form {
  display: block;
  width: 100%;
  line-height: 41px;
  padding: 0 1em;
  box-sizing: border-box;
  background-image: url(/images/v2/pc/icon-meil.png);
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 18px;
  background-color: $white;
  position: relative;
  border: none;
  border-bottom: 1px solid $gray-l;
  font-size: 14px;
  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    display: block;
    width: 100%;
    line-height: 41px;
    border-bottom: 1px solid $gray-l;
    padding: 0 1em;
    box-sizing: border-box;
    background-image: url(/images/v2/pc/icon-arrow-dn.png);
    background-position: 95% center;
    background-repeat: no-repeat;
    background-size: 12px;
    background-color: $white;
    position: relative;
  }
  &__switch {
    input {
      display: none;
    }
    label {
      display: block;
      float: left;
      cursor: pointer;
      width: 50%;
      margin: 0;
      padding: 0.8em;
      background: $white;
      color: $gray;
      font-size: 15px;
      text-align: center;
      line-height: 1;
      transition: 0.2s;
      border: 1px solid $gray-l;
    }
    label:first-of-type {
      border-radius: 3px 0 0 3px;
    }
    label:last-of-type {
      border-radius: 0 3px 3px 0;
    }
    .switch-on {
      background-color: $orange;
      color: $white;
    }
    .switch-off {
      &:hover {
        background-color: $orange-l;
      }
    }
    &--four {
      @extend .c-form__switch;
      label {
        width: 20%;
        &:last-child {
          width: 40%;
        }
      }
    }
    &--five {
      @extend .c-form__switch;
      label {
        width: 15%;
        &:last-child {
          width: 40%;
        }
      }
    }
  }
  &__input-text {
    border: 1px solid $gray-l;
    border-radius: 4px;
    font-size: 15px;
    line-height: 1.3em;
    padding: 0.8em 0.6em;
    margin: 0 !important;
    vertical-align: top;
  }
  &__input-button {
    border: 1px solid $orange;
    border-radius: 0 4px 4px 0;
    font-size: 15px;
    line-height: 1.3em;
    padding: 0.8em 0.6em;
    background: $orange;
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 !important;
    vertical-align: top;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  &__select-day-box {
    position: absolute;
    top: 101px;
    right: 0;
    border: 2px solid $orange;
    border-radius: 7px;
    z-index: 10;
    background: $white;
    padding: 18px 0 8px 0;
    width: 300px;
    box-shadow: 0 2px 4px $gray-bs;
    &:before {
      content: '';
      display: block;
      height: 12px;
      width: 12px;
      background: $white;
      border: 2px solid $orange;
      position: absolute;
      top: -8px;
      right: 83px;
      transform: rotate(-45deg);
    }
    &:after {
      content: '';
      display: block;
      height: 18px;
      width: 100%;
      background: $white;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 7px;
    }
    .year-month {
      text-align: center;
      font-size: 16px;
      margin: 0 0 0.8em 0;
    }
  }
}
