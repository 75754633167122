
@import '@sass/sp/foundation/_color-map.sass';

.p-aen {
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  background: $white;

  & img {
    width: 100%;
  }

  &__header {
    width: 100%;
    background: #fff9f5;

    &__txt {
      font-size: 13px;
      line-height: 1.7;
      text-align: center;

      @media screen and (max-width: 320px) {
        font-size: 11px;
      }
    }

    &__link {
      width: 100%;
      margin: 20px 0;

      &__btn {
        width: 50%;
        margin: 0 auto;
        padding: 10px 0;
        font-weight: bold;
        color: $white;
        border: none;
        border-radius: 30px;
        background: $dark-orange;
        display: block;
        position: relative;
        appearance: none;
        cursor: pointer;

        &::after {
          content: '';
          width: 5px;
          height: 5px;
          border-top: solid 1px $white;
          border-right: solid 1px $white;
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    &__list {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      background: $white;

      &__item {
        width: 100%;
        padding: 20px 30px;
        font-weight: bold;
        border-top: dotted 1px #f2f2f2;
        position: relative;
        cursor: pointer;

        &:first-child {
          border-top: solid 1px #f2f2f2;
        }

        &::after {
          content: '';
          width: 7px;
          height: 7px;
          border-top: solid 2px $black;
          border-right: solid 2px $black;
          display: block;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-85%) rotate(135deg);
        }

        & > span {
          color: #ec5508;
        }
      }
    }
  }

  &__point {
    width: 100%;
    padding-top: 40px;
    border-top: solid 3px $black;

    &__icon {
      width: 20%;
      margin: 0 auto;
    }

    &__ttl {
      margin: 20px 0 15px 0;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #ec5508;

      @media screen and (max-width: 320px) {
        font-size: 22px;
      }
    }

    &__visual {
      width: 91%;
      margin: 0 auto;
    }

    &__before {
      width: 91%;
      margin: 10px auto 0 auto;

      &__ttl {
        width: 20%;
      }

      &__txt {
        width: 100%;
        margin-top: 5px;
        padding: 15px 0;
        font-weight: bold;
        text-align: center;
        color: $white;
        border-radius: 5px;
        background: #a8a8a8;
      }
    }

    &__arw {
      width: 5%;
      margin: 10px auto -10px auto;
    }

    &__after {
      width: 91%;
      margin: 10px auto 0 auto;

      &__ttl {
        width: 16%;
      }

      &__txt {
        width: 100%;
        margin-top: 5px;
        padding: 15px 0;
        font-weight: bold;
        text-align: center;
        color: $white;
        border-radius: 5px;
        background: #ec5508;
      }
    }

    &__image {
      width: 100%;
      margin: 10px 0 -2px 0;
    }

    &__detail {
      width: 100%;
      padding: 20px;
      background: #feeee7;
      $detail: #{&};

      &__check {
        display: none;

        &:checked + #{$detail}__label {
          display: none;
        }

        &:checked ~ #{$detail}__contents {
          height: auto;
          padding: 15px;
          opacity: 1;
        }
      }

      &__label {
        width: 55%;
        margin: 0 auto;
        padding: 15px 0;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        border-radius: 20px;
        background: $white;
        display: block;
        position: relative;

        @media screen and (max-width: 320px) {
          font-size: 11px;
        }

        &::after {
          content: '';
          width: 15px;
          height: 15px;
          background-image: url(~@images/analysis/ico_plus@3x.png);
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);

          @media screen and (max-width: 320px) {
            right: 12px;
          }
        }
      }

      &__contents {
        width: 100%;
        height: 0;
        padding: 0;
        font-size: 13px;
        line-height: 1.8;
        color: #555;
        border-radius: 5px;
        background: $white;
        overflow: hidden;
        opacity: 0;
        transition: 0.3s;

        @media screen and (max-width: 320px) {
          font-size: 11px;
        }

        & > span {
          margin-bottom: 2px;
          font-size: 15px;
          font-weight: bold;
          color: #ec5508;
          display: block;

          &.mt20 {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &__footer {
    width: 100%;
    padding: 10px 0;
    background: #ec5508;

    &__img {
      width: 18%;
      margin: 10px auto;

      @media screen and (max-width: 320px) {
        width: 16%;
        margin: 5px auto;
      }
    }

    &__txt {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 1.6;
      color: $white;

      @media screen and (max-width: 320px) {
        font-size: 14px;
      }
    }
  }

  &__copyright {
    width: 100%;
    padding: 30px 0;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: $white;
    background: #464646;

    @media screen and (max-width: 320px) {
      font-size: 10px;
    }
  }

  #point2,
  #point3 {
    border-top: none;
    position: relative;

    &::before {
      content: '';
      width: 90%;
      border-top: solid 3px $black;
      position: absolute;
      top: 0;
      left: 5%;
    }

    & .p-aen__point__image {
      margin-top: 40px;
    }
  }
}
