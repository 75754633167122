.l-header-area {
  font-weight: 100;
  @include clf;
  background: $orange;
  position: fixed;
  width: 100%;
  min-width: 1100px;
  top: 0;
  left: 0;
  z-index: 100;
  @media screen and (max-width: $bp-mid) {
    padding-top: 88px;
  }
  .c-power-score {
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 298px;
    padding-left: 238px;
    @media screen and (max-width: $bp-mid) {
      padding-right: 0;
    }
    .c-power-score__detail--left,
    .c-power-score__detail--right {
      color: $white;
    }
    .c-power-score__detail--right {
      background: #ef993a;
    }
    .c-power-score__detail__data {
      div {
        &:first-child {
          color: $white;
        }
        &:last-child {
          color: $white;
        }
      }
    }
  }
}
.l-header-area__main-logo {
  background: $white;
  width: 230px;
  min-height: 88px;
  @extend %border-bottom-default;
  display: block;
  padding: 20px 20px 19px 20px;
  @include fleft;
  position: relative;
  z-index: 10;
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 182px;
    margin: 0 auto;
  }
  @media screen and (max-width: $bp-mid) {
    position: absolute;
    top: 0;
    left: 0;
  }
}
