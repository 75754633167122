.p-house-form {
  header {
    margin: 5.5em 0 2em 0;
    padding: 2em 0;
    text-align: center;
  }
  &__title {
    font-size: 34px;
    color: $orange;
    font-weight: 400;
  }
  &__lead-sentence {
    color: $gray;
    line-height: 1.5;
    margin-top: 1em;
  }
  &__body {
    background: $orange-ll;
    padding: 2em 0;
  }
  dl {
    @include clf;
    max-width: 700px;
    margin: 0 auto 1em auto;
    font-size: 14px;
    dt {
      @include fleft;
      width: 7em;
      font-weight: 600;
      text-align: left;
      line-height: 39px;
    }
    dd {
      padding-left: 7em;
      text-align: left;
      line-height: 3em;
    }
  }
  &__button-wrap {
    max-width: 700px;
    margin: 0 auto;
    border-bottom: 1px solid #e2e3e4;
    padding: 2em 0;
    text-align: right;
  }
}
