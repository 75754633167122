//foundation
@import 'foundation/_color';

//BreakPoint
$bp-mid: 1300px;
$bp-lg: 1450px;

//tips
@mixin clf {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin fleft {
  float: left;
}

@mixin fright {
  float: right;
}

//placeholder
%border-bottom-default {
  border-bottom: 1px solid $gray-l;
}

//webfont
%roboto {
  font-family: 'Roboto', sans-serif;
}

//foundation
@import 'foundation/_base';

//layout
@import 'layout/_login-header';
@import 'layout/_header-area';
@import 'layout/_main-area';
@import 'layout/_side-area';
@import 'layout/_document-wrap';
@import 'layout/_document-side';
@import 'layout/_document-footer';

//component
@import 'object/component/_area-info';
@import 'object/component/_global-navigation';
@import 'object/component/_modal-target';
@import 'object/component/_tab-title';
@import 'object/component/_document-base';
@import 'object/component/_document-title';
@import 'object/component/_document-category';
@import 'object/component/_lead-sentence';
@import 'object/component/_youtube-box';
@import 'object/component/_image-by';
@import 'object/component/_footer-banner';
@import 'object/component/_graph';
@import 'object/component/_form';
@import 'object/component/_search-box';
@import 'object/component/_table';
@import 'object/component/_list';
@import 'object/component/_button';
@import 'object/component/_hr';
@import 'object/component/_icon';
@import 'object/component/_calendar';
@import 'object/component/_nav-select-date';
@import 'object/component/_photo-module';
@import 'object/component/_external-link';
@import 'object/component/_view-count';
@import 'object/component/_terms';
@import 'object/component/_power-score';
@import 'object/component/_billing-box';
@import 'object/component/_pager';
@import 'object/component/_nodata';
@import 'object/component/_household-label';
@import 'object/component/_contact';
@import 'object/component/_counter';
@import 'object/component/_appliances-chart';
@import 'object/component/_comparison-chart';
@import 'object/component/_statusbar';
@import 'object/component/_attention';
@import 'object/component/_opt-out';

//project
@import 'object/project/_contract-applying';
@import 'object/project/_classic-top';
@import 'object/project/_house-form';
@import 'object/project/_daily';
@import 'object/project/_monthly';
@import 'object/project/_weekly';
@import 'object/project/_yearly';
@import 'object/project/_billing';
@import 'object/project/_topic';
@import 'object/project/_login';
@import 'object/project/_login-caution';
@import 'object/project/_help';

//utility
@import 'object/utility/_float';
@import 'object/utility/_text-style';
@import 'object/utility/_text-align';
@import 'object/utility/_link';
@import 'object/utility/_v-transition';
