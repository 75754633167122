.c-nodata {
  background: $gray-ll;
  text-align: center;
  padding: 80px;
  color: $gray !important;
}
.c-nodata__icon {
  margin-bottom: 30px;
}
.c-nodata__title {
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 1em;
  small {
    margin-top: 1em;
    display: block;
    font-size: 10px;
  }
}
