.c-search-box {
  .c-form__input-text {
    width: 100%;
  }
}
.c-search-box__elm {
  position: relative;
}
.c-search-box__buttong + .c-search-box__elm {
  display: none;
}
.c-search-box__buttong {
  color: $gray;
  font-weight: 600;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-bottom: -2px;
    margin-right: 3px;
    background-image: url(/images/v2/pc/icon-search.png);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center center;
  }
}
