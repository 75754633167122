.c-status-bar {
  list-style: none;
  padding: 0;
  margin: 0 0 2em 0;
}
.c-status-bar__elm {
  display: inline-block;
  padding: 0.8em 1.6em 0.8em 2.6em;
  font-size: 14px;
  color: $gray;
  border-top: 1px solid $gray-l;
  border-bottom: 1px solid $gray-l;
  position: relative;
  z-index: 1;
  line-height: 1 !important;
  &:after,
  &:before {
    content: '';
    display: block;
    height: 26px;
    width: 26px;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
  }
  &:after {
    background: $white;
    right: -12px;
  }
  &:before {
    background: $gray-l;
    right: -14px;
  }
  &:first-child {
    border-left: 1px solid $gray-l;
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-right: 1px solid $gray-l;
    border-radius: 0 5px 5px 0;
    &:after,
    &:before {
      content: none;
    }
  }
}

.c-status-bar__elm--current {
  @extend .c-status-bar__elm;
  z-index: 0;
  background: $orange;
  color: $white;
  border: 1px solid $orange;
  &:after {
    background: $orange;
  }
  &:before {
    background: $orange;
  }
}
