//メインカラム
.l-main-area {
  position: absolute;
  top: 88px;
  left: 0;
  width: 100%;
  min-width: 1100px;
  padding-left: 230px;
  @include clf;
}

//シングルレイアウト
.l-single-area {
  @include clf;
  padding-bottom: 60px;
}
