.c-billing-box {
  padding: 3em 0 1.6em 0;
  border-bottom: 1px solid $gray-l;
  margin-bottom: 2em;
  dl {
    @include clf;
    max-width: 70%;
    margin: 0 auto 1.3em auto;
    dt {
      @include fleft;
      width: 40%;
      vertical-align: middle;
      span {
        font-size: 11px;
      }
    }
    dd {
      @include fleft;
      text-align: right;
      width: 60%;
    }
  }
  .label-price-big {
    padding-top: 20px;
  }
  .label-price-small {
    padding-top: 13px;
  }
  .price-big {
    font-size: 58px;
    span {
      font-size: 19px;
    }
    color: $orange;
    font-weight: 100;
  }
  .price-small {
    font-size: 40px;
    span {
      font-size: 17px;
    }
    font-weight: 100;
  }
}
